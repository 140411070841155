import { render, staticRenderFns } from "./userVIP.vue?vue&type=template&id=6037ed6e&scoped=true&"
import script from "./userVIP.vue?vue&type=script&lang=js&"
export * from "./userVIP.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6037ed6e",
  null
  
)

export default component.exports